<template>
    <div class="ol-body" style="margin-top:0px;background:#ffffff;">
        <div class="back-home">
            <a href="/"><i class="fa fa-arrow-left"></i> &nbsp; Kembali Belanja</a>
        </div>
        <header>
            <div class="header-content">
                <div class="logo-interior">
                    <a href="/desain-interior">
                        <img src="https://cdn.morefurniture.id/MORE/asset/morelogo_nd.png" alt="Logo More Furniture">
                    </a>
                </div>
                <nav class="menu-interior">
                    <ul>
                        <li><a @click.prevent="scrollToHash('ruangan')" href="#ruangan" class="scrollLink">Ruangan</a></li>
                        <li><a @click.prevent="scrollToHash('proyek')" href="#proyek" class="scrollLink">Proyek</a></li>
                        <li><a @click.prevent="scrollToHash('testimoni')" href="#testimoni" class="scrollLink">Testimoni</a></li>
                        <!-- <li><a @click.prevent="scrollToHash('faq')" href="#faq" class="scrollLink">FAQ</a></li> -->
                    </ul>
                </nav>
                <div class="consultation-button">
                    <a href="http://bit.ly/more-custom" target="_blank">Konsultasi Sekarang</a>
                </div>
                <div class="burger-menu" @click="toggleMenu">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </div>
        </header>
        <div class="mobile-menu" id="mobileMenu">
            <ul>
                <li><a @click.prevent="scrollToHash('ruangan')" href="#ruangan" class="scrollLink">Ruangan</a></li>
                <li><a @click.prevent="scrollToHash('proyek')" href="#proyek" class="scrollLink">Proyek</a></li>
                <li><a @click.prevent="scrollToHash('testimoni')" href="#testimoni" class="scrollLink">Testimoni</a></li>
                <!-- <li><a @click.prevent="scrollToHash('faq')" href="#faq" class="scrollLink">FAQ</a></li> -->
            </ul>
        </div>
        <div style="margin-top:120px;"></div>
        <section class="video-section">
            <video controls id="bg-video" autoplay loop muted style="border-radius: 4px;">
                <source :src="require('@/assets/interior.webm')" type="video/webm">
                Your browser does not support HTML5 video.
            </video>
            <div class="content-video">
                <!-- <h1>Selamat Datang!</h1> -->
                <p class="font-video">50++ Desain Interior <br> Impian Telah Terwujud<br> Bersama MORE</p>
            </div>
        </section>
        <div class="ol-wrapper ol-wrapper--large margin-top" >
            <div class="row no-gutters" style="background: #F6F7F9;padding: 16px;">
                <div class="col-12">
                    
                <div class="title-div-content">
                    <h2 class="heading-content text-form" >Gratis Perhitungan Estimasi Biaya</h2>
                    <span class="sub-heading-content"></span>
                </div>
                <div class="ol-product-content ol-product-related" >
                    <div class="row"> 
                    <div class="col-md-12 col-lg-3"> 
                        <label class="label-input-interior">Nama</label>
                        <div style="margin-top:4px;">
                            <input type="text" class="input-interior" placeholder="Masukkan Nama" v-model="input.name">
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-3"> 
                        <label class="label-input-interior">Lokasi</label>
                        <div style="margin-top:4px;">
                            <input type="text" class="input-interior" placeholder="Masukkan Lokasi" v-model="input.location">
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-3"> 
                        <label class="label-input-interior">No. Whatsapp</label>
                        <div style="margin-top:4px;">
                            <input type="text" class="input-interior" placeholder="Masukkan No. Whatsapp" v-model="input.phone">
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-3"> 
                        <label>&nbsp;</label>
                        <div style="margin-top:4px;">
                            <button class="button-interior" v-on:click="submit">Tanya Harga</button>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        
        <div class="ol-wrapper ol-wrapper--large margin-top" id="ruangan">
            <div class="row no-gutters">
                <div class="col-12">
                    <div class="title-div-content">
                        <h2 class="heading-content">Desain Interior Berdasarkan Ruangan</h2>
                        <span class="sub-heading-content">Inspirasi Desain Sesuai Ruangan Hunianmu</span>
                    </div>
                </div>
            </div>
            <div class="container-interior">
                
                <div class="card-interior">
                    <a href="/desain-interior/kitchen-set">
                        <img :src="require('@/assets/images/interior/category/ruangan-kitchen-set.jpg')" alt="Gambar 1">
                        <div class="overlay-interior active">
                            <div class="content-interior">
                                <h2>Kitchen Set</h2>
                                <a href="/desain-interior/kitchen-set"><i class="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="card-interior">
                    <a href="/desain-interior/rumah">
                        <img :src="require('@/assets/images/interior/category/ruangan-rumah.jpg')" alt="Gambar 2">
                        <div class="overlay-interior active">
                            <div class="content-interior">
                                <h2>Rumah</h2>
                                <a href="/desain-interior/rumah"><i class="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </a>
                </div>
                
                <div class="card-interior">
                    <a href="/desain-interior/apartemen">
                        <img :src="require('@/assets/images/interior/category/ruangan-apartement.jpg')" alt="Gambar 2">
                        <div class="overlay-interior active">
                            <div class="content-interior">
                                <h2>Apartemen</h2>
                                <a href="/desain-interior/apartemen"><i class="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="card-interior">
                    <a href="/desain-interior/hotel">
                        <img :src="require('@/assets/images/interior/category/ruangan-hotel.jpg')" alt="Gambar 2">
                        <div class="overlay-interior active">
                            <div class="content-interior">
                                <h2>Hotel</h2>
                                <a href="/desain-interior/hotel"><i class="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="card-interior">
                    <a href="/desain-interior/kantor">
                        <img :src="require('@/assets/images/interior/category/ruangan-kantor.jpg')" alt="Gambar 2">
                        <div class="overlay-interior active">
                            <div class="content-interior">
                                <h2>Kantor</h2>
                                <a href="/desain-interior/kantor"><i class="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="card-interior">
                    <a href="/desain-interior/kamar-anak">
                        <img :src="require('@/assets/images/interior/category/ruangan-kamar-anak.jpg')" alt="Gambar 2">
                        <div class="overlay-interior active">
                            <div class="content-interior">
                                <h2>Kamar Anak</h2>
                                <a href="/desain-interior/kamar-anak"><i class="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="ol-wrapper ol-wrapper--large margin-top" id="proyek">
            <div class="row no-gutters" >
                <div class="col-12">
                    <div class="title-div-content">
                        <h2 class="heading-content">Proyek Custom Interior MORE</h2>
                        <span class="sub-heading-content">Koleksi Hasil Desain Interior Pelanggan Berkualitas</span>
                    </div>
                </div>
            </div>
            <ListCustomer :items="items" /> 
        </div>
        <div class="margin-top padding-usp-interior">
            <div class="ol-wrapper ol-wrapper--large" >
                <div class="title-div-content">
                    <h2 class="heading-content">Keunggulan More Custom</h2>
                    <span class="sub-heading-content">Nikmati Banyak Keunggulan Jasa Custom Interior MORE dengan Hasil yang Terbaik!</span>
                </div>
                <div class="row no-gutters" style="margin-top:32px;font-weight: 700;font-size: 12px;color: #141414;"> 
                    <div class="col-4 col-lg-2"> 
                        <div style="margin-top:8px;"><img :src="require('@/assets/images/interior/konsultasi.png')"  width="50" height="50" /></div>
                        <div style="margin-top:8px;">Gratis <br>Interior</div>
                    </div>
                    <div class="col-4 col-lg-2"> 
                        <div style="margin-top:8px;"><img :src="require('@/assets/images/interior/desain.png')"  width="50" height="50" /></div>
                        <div style="margin-top:8px;">Desain Interior<br> Milenial</div>
                    </div>
                    <div class="col-4 col-lg-2"> 
                        <div style="margin-top:8px;"><img :src="require('@/assets/images/interior/garansi.png')"  width="50" height="50" /></div>
                        <div style="margin-top:8px;">Garansi <br>2 Tahun</div>
                    </div>
                    <div class="col-4 col-lg-2"> 
                        <div style="margin-top:8px;"><img :src="require('@/assets/images/interior/transparan.png')"  width="50" height="50" /></div>
                        <div style="margin-top:8px;">Harga<br> Transparan</div>
                    </div>
                    <div class="col-4 col-lg-2"> 
                        <div style="margin-top:8px;"><img :src="require('@/assets/images/interior/cicilan.png')"  width="50" height="50" /></div>
                        <div style="margin-top:8px;">Cicilan<br> 0%</div>
                    </div>
                    <div class="col-4 col-lg-2"> 
                        <div style="margin-top:8px;"><img :src="require('@/assets/images/interior/tim.png')"  width="50" height="50" /></div>
                        <div style="margin-top:8px;">Tim <br>Berpengalaman</div>
                    </div>

                </div>
            </div>
        </div>
        <div class="ol-wrapper ol-wrapper--large margin-top">
            <div class="row no-gutters" >
                <div class="col-12">
                    <div class="title-div-content">
                        <h2 class="heading-content">6 Proses Wujudkan Ruangan Idaman</h2>
                        <span class="sub-heading-content">Wujudkan Interior bersama custom Interior MORE</span>
                    </div>
                </div>
            </div>
            <div class="row no-gutters" >
                <div class="col-md-12 col-lg-3">
                    <div style="position: relative; width: 100%; height: 100%; border-radius: 4px;">
                        <!-- <div :style="'background: url('+currentImage+') no-repeat; background-size: cover; border-radius: 4px; position: relative;'" class="image-cover-step"> -->
                        <div :style="backgroundImageStyle" class="image-cover-step" >
                            <div style="background: linear-gradient(0deg, rgba(0,0,0,0.42620798319327735) 0%, rgba(0,0,0,0) 100%);
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 4px;" >
                            </div>

                            <div style="position: absolute; bottom: 0; width: 100%; text-align: center; padding: 10px;">
                                <!-- <h2 style="color: white;text-align: left;">Kamar Anak</h2> -->
                            </div>

                        </div>
                    </div>
                    <!-- <div class="step-image-container" style="display:none;">
                        <img :src="currentImage" alt="Active Image" id="activeImage" />
                    </div> -->
                </div>
                <div class="col-md-12 col-lg-9">
                    
                    <div class="step-container" >
                        <div
                            v-for="(item, index) in steps"
                            :key="item.id"
                            :id="item.id"
                            :class="['step-item', { 'step-active': activeItem === item.id }]"
                            
                        >
                        <!-- @click="handleClick(index)" -->
                            <div class="step-progress-bar" :id="'progress' + (index + 1)">
                                <div class="step-progress" :id="'progress-inner' + (index + 1)"></div>
                            </div>
                            <span>{{ item.title }}</span>
                            <p>{{ item.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ol-wrapper ol-wrapper--large margin-top" >
            <div class="row no-gutters" style="background: #F6F7F9;">
            <div class="col-md-12 col-lg-6">
                <div class="content-location">
                    <div class="content-location-detail">
                        <h4 style="font-weight: 800;line-height: 34px;color: #141414;">Lebih Dekat dengan MORE Custom Interior!</h4>
                        <div class="content-location-info">
                            <table>
                            <tr>
                                <td style="padding:4px;"><img :src="require('@/assets/images/interior/location.png')"  width="16" height="16" style="vertical-align: top;"/></td>
                                <td style="padding:4px;">Jl. Mayjen HR. Muhammad No.33, Pradahkalikendal, Kec. Dukuhpakis, Surabaya, Jawa Timur 60226</td>
                            </tr>
                            <tr>
                                <td style="padding:4px;"><img :src="require('@/assets/images/interior/time.png')"  width="16" height="16" /></td>
                                <td style="padding:4px;">09:00 - 21:00</td>
                            </tr>
                            <tr>
                                <td style="padding:4px;"><img :src="require('@/assets/images/interior/phone.png')"  width="16" height="16" /></td>
                                <td style="padding:4px;">+62 811 306 6171</td>
                            </tr>
                            </table>
                        </div>
                        <div style="margin-top:16px;margin-bottom:16px;">
                            <a class="content-location-button-1" href="https://www.instagram.com/moreinterior.id/" target="_blank">Ikuti Kami di Instagram</a>
                            <a class="content-location-button-2" href="https://maps.app.goo.gl/y8WwqUPFLWdjVzTg6" target="_blank">Menuju Lokasi</a>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="col-md-12 col-lg-6">
                <img :src="require('@/assets/images/interior/Facade-More-521x316.jpg')"  width="100%" height="100%" />
            </div>
            </div>
        </div>
        <div class="ol-wrapper ol-wrapper--large margin-top"  id="testimoni">
            <div class="title-div-content">
                <h2 class="heading-content">Apa Kata Mereka tentang MORE Custom Interior</h2>
                <span class="sub-heading-content"></span>
            </div>
            <iframe width="100%" class="video-testimoni" src="https://www.youtube.com/embed/a6_8vNX9OcU?si=NFUpIH5Ja16GX1Th" title="Testimoni More Furniture Surabaya" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe width="100%" class="video-testimoni" src="https://www.youtube.com/embed/RJ4hAy007j0?si=s730EHGm0zw1CoQf" title="Testimoni More Furniture" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <div class="ol-wrapper ol-wrapper--large margin-top"  id="faq" style="display: none;">
            <div class="row no-gutters"> 
            <div class="col-lg-12">
                <div class="title-div-content">
                    <h2 class="heading-content">Jawaban Kebutuhan Interiormu</h2>
                </div>
                <div class="">
                    <div class="accordion">
                        <input type="radio" name="radio-a" id="check1" checked>
                        <label class="accordion-label" for="check1">Apa syarat dan ketentuan pengerjaan Desain Interior dengan MORE ? </label>
                        <div class="accordion-content">
                        <p>Lokasi proyek tidak dalam masa pembangunan atau sedang berlangsung</p>
                        </div>
                    </div>
                    <div class="accordion">
                        <input type="radio" name="radio-a" id="check2">
                        <label class="accordion-label" for="check2">Berapa lama durasi pengerjaan Desain Interior bersama MORE?</label>
                        <div class="accordion-content">
                        <p>I hope you are enjoing the video, don't forget to give your feedback in comment section</p>
                        </div>
                    </div>
                    <div class="accordion">
                        <input type="radio" name="radio-a" id="check3">
                        <label class="accordion-label" for="check3">Apakah terdapat promo menarik dari MORE Custom Interior</label>
                        <div class="accordion-content">
                        <p>If you liked then don't forget to subscribe the channel for latest videos. </p>
                        </div>
                    </div>
                    </div>
            </div>
            </div>
        </div>
        <div class="break-24 retail-dekstop"></div>
        <div class="break-24 retail-dekstop"></div>
        <div class="break-24 retail-dekstop"></div>
    </div>    
</template>

<script>
import globalUrl from '@/util/globallUrl'
import ListCustomer from "@/components/interior/ListCustomer.vue"

const URL = globalUrl.WS_KONSULTASI;
import { mapGetters,mapActions } from 'vuex'
export default {
    name: "Interior",
    components:{
        ListCustomer
    },
    data(){
        return {
            input: {
                name:null,
                phone: null,
                location:null,
                email: "budirizal3@gmail.com",
                address:1,
                property_id:1,
                property_type:1,
            },
            steps: [
                {
                    id: 'item1',
                    title: '1. Konsultasi',
                    description: 'Kamu bisa menghubungi tim admin kami terkait konsultasi interior yang kamu impikan. GRATIS!',
                },
                {
                    id: 'item2',
                    title: '2. Survei Pertama',
                    description: 'Tim kami akan cepat tanggap melakukan survei ke tempatmu setelah perjanjian dibuat',
                },
                {
                    id: 'item3',
                    title: '3. Biaya Komitmen',
                    description: 'Setelah perjanjian lebih lanjut disepakati, kamu wajib membayar commitment fee terlebih dahulu',
                },
                {
                    id: 'item4',
                    title: '4. Proses Desain',
                    description: 'Proses desain dilaksanakan sesuai kebutuhan dan keinginanmu bersama tim-tim profesional MORE',
                },
                {
                    id: 'item5',
                    title: '5. Produksi & Instalasi',
                    description: 'Tim produksi akan langsung mengeksekusi desain interiormu pilihanmu, kamu tinggal bersantai deh!',
                },
                {
                    id: 'item6',
                    title: '6. Serah Terima',
                    description: 'Setelah handover, kami memberikan garansi agar kamu bisa menikmati interior impianmu lebih leluasa',
                },
            ],
            images: [
                require('@/assets/images/retail/A6K00287.jpg'),
                require('@/assets/images/retail/A6K00340.jpg'),
                require('@/assets/images/retail/A6K00313.jpg'),
                require('@/assets/images/retail/A6K00317.jpg'),
                require('@/assets/images/retail/A6K00287.jpg'),
                require('@/assets/images/retail/A7_08004.jpg')
            ],
            activeItem: null,
            currentIndex: 0,
            progressDuration: 5000,
            statusReset:false,
            filteredBy:this.$store.state.product.list.filtered,
            loading:true,
            list:{
                more:false,
                total:0,
            },
        }
    },
    computed:{
        ...mapGetters('auth', ['location','authenticated']),
        ...mapGetters("interior",["items","filtered"]),
        currentImage() {
            return this.images[this.currentIndex];
        },
        backgroundImageStyle() {
            return {
                backgroundImage: `url(${this.currentImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: '4px',
                position: 'relative'
            }
        }

    },
    methods:{
        ...mapActions("interior",["fetchItems"]),
        scrollToHash(hash) {
            hash = hash.replace('#', '');
                // const h = document.getElementsByTagName('header')[0].offsetHeight + document.getElementById('header-promotion').offsetHeight;
                const el = document.getElementById(hash);
                if(el) {
                    var top = el.getBoundingClientRect().top + window.pageYOffset;
                    top= top-110;
                    console.log(top);
                    window.scrollTo({top: top, behavior: 'smooth'});
                    this.$router.push({hash: hash});
            }
        },
        
        toggleMenu() {
            var menu = document.getElementById("mobileMenu");
            menu.classList.toggle("show");
        },
        async init(){
            
        },
        activateItem(index) {
            if (this.activeItem !== null) {
                this.resetProgress(this.activeItem);
            }
            this.activeItem = this.steps[index].id;
            this.currentIndex = index;
            this.animateProgress(index);
            setTimeout(() => {
                this.currentIndex = (this.currentIndex + 1) % this.steps.length;
                this.activateItem(this.currentIndex);
            }, this.progressDuration);
        },
        resetProgress(itemId) {
            const progressInner = document.getElementById(`progress-inner${this.steps.findIndex(item => item.id === itemId) + 1}`);
            progressInner.style.width = '0';
            progressInner.style.transition = 'none';
        },
        animateProgress(index) {
            const progressInner = document.getElementById(`progress-inner${index + 1}`);
            progressInner.style.transition = 'width 5s linear';
            progressInner.style.width = '100%';
        },
        handleClick(index) {
            this.currentIndex = index;
            this.activateItem(index);
        },
        submit(){
            if(this.input.name  && this.input.phone && this.input.location ){
               
                this.loading=true;
                URL.SAVE(this.input).then(response => {
                    fbq('track', 'Lead', {
                        content_name: "MORE CUSTOM",
                        content_category:"Konsultasi",
                        currency:"IDR",
                        value:1
                    });

                    let data = response.data.data;
                    this.input.name = data.name;
                    this.input.phone = data.phone;
                    this.input.location = data.location;
                    this.error = false;
                    this.success=true;
                    this.loading=false;
                    let texts = "Hi More Furniture! Saya mau konsultasi furniture. Berikut identitas saya: Nama: "+
                    this.input.name+", HP: "+
                    this.input.phone+", Lokasi: "+this.input.location+"  MORE Siap Membantumu.";
                    let sendWa ={
                        phone:process.env.VUE_APP_WA_KONSULTASI,
                        text:texts
                    }
                    window.open('https://api.whatsapp.com/send?'+$.param(sendWa), '_blank');
                }).catch(error => {
                    // console.log("submit",error);
                    this.message = error.response.data.message;
                    this.error = true;
                    this.success=false;
                    this.loading=false;
                });
            }else{
                this.error = true;
                this.success=false;
            }
        },
        async getItems(firstPage = false,per_page=null){
            this.loading = true;
            if(firstPage) this.filteredBy.page = 1;            
            if(per_page != null) this.filteredBy.per_page = per_page;

            // if(this.copiedFilter && this.$route.params.filter) this.retrieveCopiedFilter();
            // this.retrieveCopiedFilter();
            
            this.$store.commit("interior/setFiltered",this.filteredBy);
            
            const results = await this.fetchItems();
            
            this.statusReset = false;
            this.loading = false;
            if(results.success){
                this.list.total = results.data.total; 
                this.list.more = results.data.pagination.more;
                if(this.list.more) {
                    this.filteredBy.page=this.filteredBy.page+1;
                    this.$store.commit("interior/setFiltered",{page:this.filteredBy.page});
                }
            }else{
                this.$toast.open({ message: "Gagal mendapatkan data pelanggan", type: "error", duration: 3000, })
            }
        },
        retrieveCopiedFilter(){
            this.statusReset = true;
            let query = _.mapKeys(queryString.parse(this.$route.params.filter), (value, key) => key.replace("[]", "") );

            query.per_page = this.filteredBy.per_page;
            query.page = 1;
            
            this.filteredBy = query;
            
            this.$store.commit("interior/setCopiedFilter", false);
            
        },
        getMetaTitle() {
            const currentURL = window.location.href;    
            // Pisahkan URL berdasarkan '/'
            const urlSegments = currentURL.split('/');
            // Ambil kata terakhir dari URL
            const cityName = urlSegments[urlSegments.length - 1];
            if(cityName == 'sidoarjo'){
                return "Jasa Desain Interior Sidoarjo & Sekitarnya | MORE Furniture";
            }else if(cityName == 'gresik'){ 
                return "Jasa Desain Interior Gresik & Sekitarnya | MORE Furniture";
            }else{
                return "Jasa Desain Interior Surabaya & Sekitarnya | MORE Furniture";
            }
        },
        getMetaDescription() {
            const currentURL = window.location.href;    
            // Pisahkan URL berdasarkan '/'
            const urlSegments = currentURL.split('/');
            // Ambil kata terakhir dari URL
            const cityName = urlSegments[urlSegments.length - 1];
            if(cityName == 'sidoarjo'){
                return "MORE Custom menawarkan jasa desain interior Sidoarjo siap membuat desain interior rumah, kantor, dan ruangan lainnya menjadi lebih indah dan berkesan mewah";
            }else if(cityName == 'gresik'){ 
                return "MORE Custom menawarkan jasa desain interior Gresik siap membuat desain interior rumah, kantor, dan ruangan lainnya menjadi lebih indah dan berkesan mewah";
            }else{
                return "MORE Custom menawarkan jasa desain interior Surabaya siap membuat desain interior rumah, kantor, dan ruangan lainnya menjadi lebih indah dan berkesan mewah";
            }
        }
    },
    async mounted(){
        await this.init();
        this.activateItem(this.currentIndex);
        await Promise.all([
            this.getItems(true),
        ]);

    },
    watch:{
        $route(to, from) {
            this.init();
        },
    },
    updated(){
        
    },
    metaInfo() {
        return {
            title: this.getMetaTitle(),
            meta: [
                { name: 'description', content: this.getMetaDescription() },
                { name: 'robots', content: 'index,follow' },
                { property: 'og:title', content: this.getMetaTitle() },
                { property: 'og:url', content: window.location.origin + this.$route.fullPath },
                { property: 'og:description', content: this.getMetaDescription() },
                { property: 'og:site_name', content: 'More Furniture' },
                { property: 'og:type', content: 'website' }
            ],
            link: [
                {rel: 'canonical', href: window.location.origin+this.$route.fullPath}
            ]
        };
    }
};
</script>

<style lang="css" scoped>
@import '../../assets/css/bootstrap.css';
@import '../../assets/css/interior.css';
</style>
